import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import { StaticImage } from "../Components/StaticImage";
import ArticleList from "rev-cms-core/dist/blog/Components/ArticleList";
import Article from "rev-cms-core/dist/blog/Components/Article";
import CreateArticleButton from "rev-cms-core/dist/blog/Components/CreateArticleButton";
import CmsForm from "rev-cms-core/dist/cms-widgets/Form";
import CmsInputField from "rev-cms-core/dist/cms-widgets/InputField";

const UI_STATE = {
  LIST: "LIST",
  DETAIL: "DETAIL",
};
class ArticleListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UI_STATE.LIST,
      articleId: null,
    };
  }

  render() {
    let { pageContext, isEditor, user, appActions } = this.props;

    let { uiState, articleId } = this.state;

    if (isEditor && !user) {
      return (
        <Wrapper>
          <CmsForm
            FieldComp={CmsInputField}
            model={{
              name: "user",
              fields: [
                {
                  name: "username",
                  type: "string",
                  label: "Name",
                  defaultValue: "",
                },
                {
                  name: "password",
                  type: "string",
                  label: "Password",
                  defaultValue: "",
                },
              ],
            }}
            instance={null}
          >
            {({ values, resetValues, renderFields }) => (
              <>
                {renderFields()}

                <button
                  onClick={() => {
                    this._onLogin(values);
                  }}
                >
                  Submit
                </button>
              </>
            )}
          </CmsForm>
        </Wrapper>
      );
    }

    if (uiState === UI_STATE.LIST) {
      return (
        <Wrapper>
          {user && user.token && (
            <CreateArticleButton token={user.token}>
              {({ isInitializing, createArticle, contextReady }) => (
                <h3
                  onClick={async () => {
                    let newArticle = await createArticle();
                    this.setState({
                      articleId: newArticle.id,
                      uiState: UI_STATE.DETAIL,
                    });
                  }}
                >
                  新增文章+
                </h3>
              )}
            </CreateArticleButton>
          )}
          <ArticleList>
            {({ articles, fetchArticles }) => (
              <div>
                {articles.map(article => (
                  <div
                    key={article.id}
                    style={{
                      cursor: "pointer",
                      padding: 10,
                      border: "1px solid #eee",
                    }}
                    onClick={() =>
                      this.setState({
                        uiState: UI_STATE.DETAIL,
                        articleId: article.id,
                      })
                    }
                  >
                    <div>Article #{article.id}</div>
                    <span>共{article.sections.length}個段落</span>
                  </div>
                ))}
              </div>
            )}
          </ArticleList>
        </Wrapper>
      );
    }

    return (
      <div>
        <h2
          onClick={() =>
            this.setState({ uiState: UI_STATE.LIST, articleId: null })
          }
        >{`< Back`}</h2>
        <Article
          id={articleId}
          token={user.token}
          SectionFieldComp={CmsInputField}
          spinnerComp={<div>Loading....</div>}
        >
          {({
            article,
            isInitializing,
            renderSection,
            actions: {
              moveSectionDown,
              moveSectionUp,
              createSection,
              removeSection,
              editArticleTitle,
              editArticleBannerImg,
              saveArticle,
              resetArticle,
              deleteArticle,
            },
          }) => {
            return (
              <div style={{ padding: 10, border: "1px solid red" }}>
                <input
                  onChange={e => editArticleTitle(e.target.value)}
                  value={article.title}
                />
                <input
                  onChange={e => editArticleBannerImg(e.target.value)}
                  value={article.image}
                />
                {article.sections.map((itemValues, itemIdx) => (
                  <div
                    key={itemIdx}
                    style={{ padding: 10, margin: 5, border: "1px solid #ccc" }}
                  >
                    {renderSection({ itemValues, itemIdx })}

                    <button onClick={() => moveSectionUp(itemIdx)}>Up</button>
                    <button onClick={() => moveSectionDown(itemIdx)}>
                      Down
                    </button>
                    <button onClick={() => removeSection(itemIdx)}>
                      Delete
                    </button>
                  </div>
                ))}

                <button onClick={createSection}>Add section</button>
                <button onClick={resetArticle}>Reset</button>
                <button
                  onClick={async () => {
                    await saveArticle();
                    this.setState({ uiState: UI_STATE.LIST, articleId: null });
                  }}
                >
                  Save
                </button>
                <button
                  onClick={async () => {
                    await deleteArticle();
                    this.setState({ uiState: UI_STATE.LIST, articleId: null });
                  }}
                >
                  Delete Article
                </button>
              </div>
            );
          }}
        </Article>
      </div>
    );
  }

  _createArticle = async createArticleFunc => {
    let article = await createArticleFunc();
    this.setState({ articleId: article.id, uiState: UI_STATE.DETAIL });
  };

  _onLogin = async values => {
    let { appActions } = this.props;
    let { username, password } = values;
    let profile = await appActions.login({ username, password });
  };
}

const Wrapper = styled.div``;

export default withPage(
  connect(
    (state, ownProps) => ({
      isEditor: ownProps.pageContext.isEditor || false,
      user: Selectors.getUser(state),
    }),
    ActionCreator
  )(ArticleListPage)
);
